import uniqueId from 'lodash/uniqueId';
import actions from './actions';

const addReportToLocalStorage = updatedLayout => {
  const queryParams = new URLSearchParams(window?.location?.search);
  const id = queryParams.get('id');
  if (!id) {
    localStorage.setItem('layout_json', JSON.stringify(updatedLayout));
  }
};

const addDefaultPlacement = sections => {
  const prevX = 0;
  let nextY = 0;

  if (!sections) {
    return [];
  }
  const updatedSections = sections.map(section => {
    if (section.style.placement.y) {
      nextY += section.style.placement.h;
      return section;
    }

    const newSectionData = {
      ...section,
      style: {
        ...section.style,
        placement: {
          ...section.style.placement,
          x: prevX,
          y: nextY
        }
      }
    };
    nextY += section.style.placement.h;

    return newSectionData;
  });

  return updatedSections;
};

const getUpdatedLayout = (prevLayout, newSection) => {
  const layout = {
    ...prevLayout,
    sections: addDefaultPlacement([...(prevLayout?.sections || []), newSection])
  };

  addReportToLocalStorage(layout);
  return layout;
};

const updateSectionPlacement = (prevLayout, sectionId, updatedPlacement) => {
  if (prevLayout.sections.length === 0) {
    return { ...prevLayout };
  }
  const updatedSectionIndex = prevLayout.sections.findIndex(sec => sec.id === sectionId);

  const section = prevLayout.sections[updatedSectionIndex];
  const updatedSection = {
    ...section,
    style: { ...section.style, placement: { ...section.style.placement, ...updatedPlacement } }
  };

  const updatedSections = [...prevLayout.sections];
  updatedSections[updatedSectionIndex] = updatedSection;
  const updatedLayout = {
    ...prevLayout,
    sections: updatedSections
  };

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const updateSectionTextContent = (prevLayout, sectionId, updatedTextContent) => {
  const updatedSectionIndex = prevLayout.sections.findIndex(sec => sec.id === sectionId);

  const section = prevLayout.sections[updatedSectionIndex];
  const updatedSection = {
    ...section,
    content: updatedTextContent
  };

  const updatedSections = [...prevLayout.sections];
  updatedSections[updatedSectionIndex] = updatedSection;

  const updatedLayout = {
    ...prevLayout,
    sections: updatedSections
  };

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const updateSectionStyle = (prevLayout, sectionId, updatedStyle) => {
  const updatedSectionIndex = prevLayout.sections.findIndex(sec => sec.id === sectionId);

  const section = prevLayout.sections[updatedSectionIndex];
  const updatedSection = {
    ...section,
    style: { ...section.style, graphStyle: updatedStyle }
  };

  const updatedSections = [...prevLayout.sections];
  updatedSections[updatedSectionIndex] = updatedSection;

  const updatedLayout = {
    ...prevLayout,
    sections: updatedSections
  };

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const updateLayout = (prevLayout, updateGridLayout) => {
  const updatedLayout = {
    ...prevLayout,
    sections: [...prevLayout.sections]
  };
  updateGridLayout.forEach(element => {
    const index = updatedLayout.sections.findIndex(sec => `${sec.id}` === element.i);
    updatedLayout.sections[index] = {
      ...updatedLayout.sections[index],
      style: {
        ...updatedLayout.sections[index].style,
        placement: {
          ...element
        }
      }
    };
  });

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const updateCoverPageTitle = (prevLayout, title) => {
  const updatedLayout = {
    ...prevLayout,
    sections: [...prevLayout.sections],
    coverPage: {
      ...prevLayout.coverPage,
      title
    }
  };

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const updateCoverPageDescription = (prevLayout, description) => {
  const updatedLayout = {
    ...prevLayout,
    sections: [...prevLayout.sections],
    coverPage: {
      ...prevLayout.coverPage,
      description
    }
  };

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const updateCoverPageTemplate = (prevLayout, template) => {
  const updatedLayout = {
    ...prevLayout,
    sections: [...prevLayout.sections],
    coverPage: {
      ...prevLayout.coverPage,
      template
    }
  };

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const updateIndicationStudies = (prevLayout, updatedLayout) => {
  const updatedData = [];

  prevLayout?.sections?.forEach(item => {
    if (item.id === updatedLayout?.id) {
      const { indicationStudies } = item.data;
      const newIndicationStudies = { ...indicationStudies };

      Object.keys(updatedLayout?.data?.indicationStudies).forEach(key => {
        if (!newIndicationStudies[key]) {
          newIndicationStudies[key] = [];
        }

        updatedLayout?.data?.indicationStudies[key].forEach(study => {
          if (!newIndicationStudies[key].includes(study)) {
            newIndicationStudies[key].push(study);
          }
        });
      });

      const formattedData = {
        ...item,
        data: { ...item.data, indicationStudies: newIndicationStudies }
      };
      updatedData.push(formattedData);
    } else {
      updatedData.push(item);
    }
  });
  const updatedLayoutData = {
    ...prevLayout,
    sections: updatedData
  };

  addReportToLocalStorage(updatedLayoutData);
  return updatedLayoutData;
};

const duplicateSection = (prevLayout, sectionId) => {
  const currentSectionIndex = prevLayout.sections.findIndex(sec => sec.id === sectionId);

  const section = prevLayout.sections[currentSectionIndex];

  const updatedSections = [...prevLayout.sections];
  const updatedSection = {
    ...section,
    id: `${section.id}-duplicate-${uniqueId()}`,
    style: {
      ...section.style,
      placement: {
        ...section.style.placement,
        y: section.style.placement.y + section.style.placement.h
      }
    }
  };

  const updatedLayout = {
    ...prevLayout,
    sections: [
      ...updatedSections.slice(0, currentSectionIndex + 1),
      updatedSection,
      ...updatedSections.slice(currentSectionIndex + 1)
    ]
  };

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const removeSection = (prevLayout, sectionId) => {
  const updatedSectionIndex = prevLayout.sections.findIndex(sec => sec.id === sectionId);

  const updatedSections = [...prevLayout.sections];
  updatedSections.splice(updatedSectionIndex, 1);

  const updatedLayout = {
    ...prevLayout,
    sections: updatedSections
  };

  addReportToLocalStorage(updatedLayout);
  return updatedLayout;
};

const reducers = (state, action) => {
  switch (action.type) {
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearch: action.value
      };
    }
    case actions.SET_CART_ITEMS: {
      return {
        ...state,
        cartItems: action.value
      };
    }
    case actions.SET_NOTIFICATION_COUNT: {
      return {
        ...state,
        notificationsCount: action.value
      };
    }
    case actions.SET_PDF_MESSAGE: {
      return {
        ...state,
        pdfMessage: action.value
      };
    }
    case actions.SET_GRAPH_TYPE: {
      return {
        ...state,
        graphType: [...state.graphType, action.value]
      };
    }
    case actions.SET_CARD_DETAILS: {
      return {
        ...state,
        cardDetails: action.value
      };
    }
    case actions.SET_USER_STUB: {
      return {
        ...state,
        userStub: action.value
      };
    }
    case actions.SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.value
      };
    }
    case actions.SET_PATENT_EXPANSION: {
      return {
        ...state,
        patentExpansion: action.value
      };
    }
    case actions.SET_APPLICATION_CONTENT: {
      return {
        ...state,
        applicationContent: [...state.applicationContent, action.value]
      };
    }
    case actions.SET_TIMELINE: {
      return {
        ...state,
        timelineData: [...state.timelineData, action.value]
      };
    }
    case actions.SET_MODULE: {
      return {
        ...state,
        module: action.value
      };
    }
    case actions.SET_CATEGORY: {
      return {
        ...state,
        category: action.value
      };
    }
    case actions.SET_REPORT: {
      return {
        ...state,
        report: state.cartItems === 0 ? action.value : [...state.report, action.value]
      };
    }
    case actions.SET_ALERT: {
      return {
        ...state,
        alert: {
          ...action.value
        }
      };
    }
    case actions.SET_DB: {
      return {
        ...state,
        db: action.value
      };
    }
    case actions.SET_EXTENDED_CARD: {
      return {
        ...state,
        extendedCard: action.value
      };
    }
    case actions.SET_BOT: {
      return {
        ...state,
        bot: action.value
      };
    }
    case actions.SET_ADCOMMCOMMITTEE: {
      return {
        ...state,
        adcommCommittee: action.value
      };
    }

    case actions.SET_TREE_DATA: {
      return {
        ...state,
        treeData: action.value
      };
    }

    case actions.SET_SEARCH_DETAILS: {
      return {
        ...state,
        searchDetails: action.value
      };
    }

    case actions.SET_PROCEDURAL_STEPS_PROD_NO: {
      return {
        ...state,
        procedural_steps_prod_no: action.value
      };
    }

    case actions.SET_EMA_PRODUCT_NO: {
      return {
        ...state,
        ema_product_no: action.value
      };
    }

    // selected application (card) for a project.
    case actions.SET_SELECTED_CARD: {
      return {
        ...state,
        selectedCard: action.value
      };
    }

    // open/close projects list dialog box.
    case actions.SET_PROJECT_DIALOG: {
      return {
        ...state,
        projectDialog: action.value
      };
    }

    // item type selected for a project (i.e. application/query/report)
    case actions.SET_ITEM_TYPE: {
      return {
        ...state,
        itemType: action.value
      };
    }

    // set project name
    case actions.SET_SUBMIT_PROJECT_NAME: {
      return {
        ...state,
        submitProjectName: action.value
      };
    }
    case actions.SET_ADVANCE_SEARCH_PAYLOAD: {
      return {
        ...state,
        advanceSearchPayload: action.value
      };
    }
    case actions.SET_ADVANCE_SEARCH_CNF_QUERY: {
      return {
        ...state,
        advanceSearchCnfQuery: action.value
      };
    }
    case actions.SET_ADVANCE_SEARCH_CONDITIONS: {
      return {
        ...state,
        advanceSearchConditions: action.value
      };
    }
    case actions.SET_NEW_SEARCH: {
      return {
        ...state,
        newSearch: action.value
      };
    }
    case actions.SET_NORMALIZED_CNF_QUERY: {
      return {
        ...state,
        normalizedCnfQuery: action.value
      };
    }
    case actions.SET_ADCOMM_MINUTES_ADD_DETAILS: {
      return { ...state, adcommMinutesAddDetails: action.value };
    }
    case actions.SET_BREADCRUMBS: {
      return { ...state, breadCrumbs: action.value };
    }
    case actions.SET_UNREAD_MESSAGES: {
      return { ...state, unreadMessages: action.value };
    }
    case actions.SET_SERVICE_RESTRICTION_DETAILS: {
      return { ...state, serviceRestriction: action.value };
    }
    case actions.SET_SHARE_LINK: {
      return { ...state, shareLink: action.value };
    }
    case actions.SET_APPLICATION_NAME: {
      return { ...state, applicationName: action.value };
    }
    case actions.SET_APPLICATION_NUMBER: {
      return { ...state, applicationNumber: action.value };
    }
    case actions.SET_APPLICATION_SOURCE: {
      return { ...state, applicationSource: action.value };
    }
    case actions.SET_APPLICATION_LIST: {
      return { ...state, applicationList: action.value };
    }
    case actions.RESET_ADD_TO_REPORT: {
      return { ...state, resetAddToReport: action.value };
    }
    case actions.ADD_TEMPLATE_TO_REPORT: {
      return { ...state, reportLayout: getUpdatedLayout(state.reportLayout, action.value) };
    }
    case actions.UPDATE_REPORT_SECTION_POSITION: {
      return {
        ...state,
        reportLayout: updateSectionPlacement(
          state.reportLayout,
          action.value.sectionId,
          action.value.updatedPlacement
        )
      };
    }
    case actions.UPDATE_REPORT_SECTIONS_POSITION: {
      return {
        ...state,
        reportLayout: updateLayout(state.reportLayout, action.value)
      };
    }
    case actions.UPDATE_REPORT_SECTION_TEXT: {
      return {
        ...state,
        reportLayout: updateSectionTextContent(
          state.reportLayout,
          action.value.sectionId,
          action.value.updatedContent
        )
      };
    }
    case actions.UPDATE_GRAPH_STYLE: {
      return {
        ...state,
        reportLayout: updateSectionStyle(
          state.reportLayout,
          action.value.sectionId,
          action.value.updatedStyle
        )
      };
    }
    case actions.REMOVE_REPORT_SECTION: {
      return {
        ...state,
        reportLayout: removeSection(state.reportLayout, action.value)
      };
    }
    case actions.DUPLICATE_REPORT_SECTION: {
      return {
        ...state,
        reportLayout: duplicateSection(state.reportLayout, action.value)
      };
    }
    case actions.UPDATE_REPORT_HEADER_LOGO: {
      const updatedLayout = {
        ...state.reportLayout,
        sections: [...state.reportLayout.sections],
        logo: { assetKey: action.value }
      };

      addReportToLocalStorage(updatedLayout);
      return {
        ...state,
        reportLayout: updatedLayout
      };
    }
    case actions.UPDATE_COVERPAGE_DESCRIPTION: {
      return {
        ...state,
        reportLayout: updateCoverPageDescription(state.reportLayout, action.value)
      };
    }
    case actions.UPDATE_COVERPAGE_TITLE: {
      return {
        ...state,
        reportLayout: updateCoverPageTitle(state.reportLayout, action.value)
      };
    }
    case actions.UPDATE_COVERPAGE_TEMPLATE: {
      return {
        ...state,
        reportLayout: updateCoverPageTemplate(state.reportLayout, action.value)
      };
    }
    case actions.REPLACE_REPORT_LAYOUT: {
      const updatedLayout = { ...action.value };
      addReportToLocalStorage(updatedLayout);
      return {
        ...state,
        reportLayout: updatedLayout
      };
    }
    case actions.UPDATE_REPORT_OWNER: {
      return {
        ...state,
        reportLayout: { ...state.reportLayout, owner: action.value }
      };
    }
    case actions.SET_REPORT_LAYOUT: {
      return {
        ...state,
        reportLayout: action.value
      };
    }
    case actions.UPDATE_SNAPSHOT_REPORT_LAYOUT: {
      return {
        ...state,
        reportLayout: updateIndicationStudies(state.reportLayout, action.value)
      };
    }

    case actions.SET_REPORT_ID: {
      const updatedLayout = { ...state.reportLayout, ...action.value };
      addReportToLocalStorage(updatedLayout);
      return {
        ...state,
        reportLayout: updatedLayout
      };
    }
    case actions.REPORT_SAVING: {
      return {
        ...state,
        reportSaving: action.value
      };
    }
    case actions.CLEAR_REPORT: {
      localStorage.removeItem('layout_json');
      return {
        ...state,
        reportLayout: {
          ...state.reportLayout,
          id: undefined,
          sections: [],
          coverPage: {
            template: 'template1',
            title: '',
            description: ''
          },
          reportFileName: undefined,
          reportFileDescription: ''
        }
      };
    }
    case actions.REPORT_DOWNLOADING: {
      return {
        ...state,
        reportDownloading: action.value
      };
    }
    case actions.REMOVE_CHAT_RIA_REPORT: {
      const updatedLayout = {
        ...state.reportLayout,
        // eslint-disable-next-line no-underscore-dangle
        sections: state.reportLayout.sections.filter(sec => sec._chatId !== action.value)
      };
      localStorage.setItem('layout_json', JSON.stringify(updatedLayout));
      return {
        ...state,
        reportLayout: updatedLayout
      };
    }
    case actions.SET_ARIA_DOCUMENT: {
      return { ...state, ariaDocument: action.value };
    }
    case actions.SET_USER_PROFILE_DOCUMENT_OPEN: {
      return {
        ...state,
        userProfileDocumentOpen: action.value
      };
    }
    case actions.SET_USER_PROFILE_DOCUMENT_LINK: {
      return {
        ...state,
        userProfileDocumentLink: action.value
      };
    }
    case actions.SET_USER_PROFILE_DOCUMENT_STATE: {
      return {
        ...state,
        userProfileDocumentState: action.value
      };
    }
    case actions.SET_USER_PROFILE_SELECTED_DOCUMENT: {
      return {
        ...state,
        userProfileSelectedDocument: action.value
      };
    }
    case actions.SET_SCROLL_TO_ARIA_PAGE: {
      return {
        ...state,
        scrollToAriaPage: action.value
      };
    }
    case actions.SET_SHOW_NEW_FILTERS: {
      return {
        ...state,
        showNewFilters: action.value
      };
    }
    case actions.SET_CHATRIA_TRIGGERED_FROM: {
      return {
        ...state,
        chatRiaTriggeredFrom: action.value
      };
    }
    case actions.SET_CHATRIA_OPEN: {
      return {
        ...state,
        chatRiaOpen: action.value
      };
    }
    case actions.TRIGGER_CHATRIA_CLOSE_SESSION: {
      return {
        ...state,
        chatRiaCloseSessionTrigger: action.value
      };
    }
    case actions.SET_LOGIN_SCREEN: {
      return {
        ...state,
        loginScreen: action.value
      };
    }
    case actions.SET_NEW_USER: {
      return {
        ...state,
        newUser: action.value
      };
    }
    case actions.SET_SOURCE_MAINTENANCE: {
      return {
        ...state,
        sourceMaintenance: action.value
      };
    }
    case actions.SET_EXPORT_TYPE: {
      return {
        ...state,
        exportType: action.value
      };
    }
    case actions.SET_MINIMIZED_CHAT_RIA: {
      return {
        ...state,
        minimizedChatRia: action.value
      };
    }
    case actions.SET_LABEL_SECTION_LIST_ADVANCE_SEARCH: {
      return {
        ...state,
        labelSectionListAdvanceSearch: action.value
      };
    }
    case actions.SET_WHATS_NEW_UPDATES: {
      return {
        ...state,
        whatsNewUpdates: action.value
      };
    }
    case actions.SET_IS_WHATS_NEW_LOADING: {
      return {
        ...state,
        isWhatsNewLoading: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
