import palette from '../../../themev5/palette';

const styles = {
  searchBar: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    maxWidth: 794,
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px'
  },
  searchBarAria: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '12px',
    maxWidth: 794
  },
  tipsBox: { flex: 1, textAlign: 'right' },
  tipsText: {
    color: 'primary.700',
    fontWeight: 600
  },
  advanceSearchIcon: {
    '& svg': {
      color: 'primary.700',
      fontSize: 24
    },
    marginX: 0,
    '&:disabled': {
      filter: 'opacity(0.5)',
      '& svg': {
        color: 'gray.lightVariant4'
      }
    }
  },
  searchButton: {
    color: 'white.main',
    textTransform: 'none',
    fontSize: 12,
    background: 'linear-gradient(to right, #3C999A, #47A836)',
    paddingX: 2,
    paddingY: 0.75,
    borderRadius: '8px',
    marginX: 1.5,
    marginY: 1,
    '&:hover': { background: 'linear-gradient(to right, #358788, #3f9530)' },
    '&:disabled': {
      background: 'linear-gradient(to right, #BDBDBD, #BDBDBD)'
    }
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mt: '10px',
    mx: 'auto',
    mb: 0,
    width: '64px'
  },
  horizontalDivider: {
    height: '32px',
    my: 0,
    mx: '16px',
    color: 'gray.300'
  },
  advanceSearchCloseIcon: {
    color: 'gray.lightVariant4',
    position: 'absolute',
    right: -10,
    top: -10,
    margin: '26px 32px 0px 0px'
  },
  advanceSearchActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    px: '16px',
    py: 0,
    height: '32px',
    '& > label,button': {
      m: '0 !important',
      '& .MuiTypography-body1': {
        width: '100px !important',
        fontSize: '13px',
        fontWeight: 400
      },
      '& .MuiSwitch-switchBase': {
        p: 0,
        mt: '-2px',
        '&.Mui-checked': {
          transform: 'translateX(12px)'
        }
      },
      '& .MuiSwitch-track': {
        my: 'auto',
        height: '9px',
        width: '18px',
        borderRadius: '3.5px'
      },
      '& .MuiSwitch-thumb': {
        my: 'auto',
        width: '12px',
        height: '12px'
      },
      '& .MuiSwitch-root': {
        overflow: 'visible',
        width: '18px',
        height: '9px',
        p: 0,
        ml: '8px',
        mr: '32px'
      }
    }
  },
  advanceSearchDateInput: {
    // width: '32%',
    p: 0,
    '& .MuiInputBase-root': {
      border: 'none',
      p: 0,
      fieldset: {
        border: 'none'
      },
      '& .MuiInputBase-input': {
        padding: 0,
        textAlign: 'left',
        fontWeight: 500,
        fontSize: '13px'
      }
    },
    '& .MuiInputAdornment-root': {
      display: 'none'
    }
  },
  advanceSearchListItem: {
    flex: 1,
    border: '1px solid',
    borderColor: 'gray.300',
    '&#highlighted': {
      borderColor: 'primary.600'
    },
    borderRadius: '8px',
    p: '4px 12px',
    height: '40px',
    width: '575px',
    mr: '8px'
  },
  advanceSearchRemoveListItem: {
    p: 0,
    '& svg': {
      fontSize: '16px !important',
      color: 'red.100'
    },
    '&:disabled': {
      '& svg': {
        color: 'gray !important'
      }
    }
  },
  advanceSearchSelect: {
    width: '128px',
    fontSize: '13px',
    p: 0,
    border: 'none',
    '& > div': {
      p: '0 18px 0 0 !important',
      zIndex: 2,
      width: '128px'
    },
    '& > svg': {
      p: 0,
      position: 'absolute',
      right: 0,
      fontSize: '14px',
      cursor: 'pointer',
      zIndex: 1
    },
    '& > input': {
      border: 'none',
      p: 0
    },
    fieldset: {
      p: 0,
      border: 'none',
      width: '128px'
    }
  },
  advanceSearchInput: {
    width: '184px',
    fontSize: '13px',
    alignSelf: 'center',
    p: 0,
    border: 'none',
    fieldset: {
      p: 0,
      border: 'none'
    },
    '& *': {
      fontSize: '13px !important',
      p: '0 !important'
    }
  },
  nextSearchCondition: {
    width: 'auto',
    height: 'auto',
    p: '4px 6px !important',
    textAlign: 'center',
    color: `${palette.primary['500']} !important`,
    background: `${palette.primary.background} !important`,
    fontSize: '12px',
    fontWeight: 700,
    '&:disabled': {
      '& svg': {
        display: 'none'
      }
    },
    '& .MuiButton-endIcon': {
      mr: 0,
      ml: 0
    }
  },
  loadingIcon: {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  conditionButtons: {
    p: 0,
    '& svg': {
      fontSize: '18px'
    },
    '&:disabled': {
      '& svg': {
        color: 'grey !important'
      }
    }
  },
  advanceSearchExactMatch: {
    width: '76px !important',
    ml: '16px',
    '& .MuiTypography-body1': {
      width: '68px !important',
      fontSize: '10px',
      margin: '-5px',
      fontWeight: 400
    },
    '& .MuiSwitch-switchBase': {
      p: 0,
      mt: '-1px',
      '&.Mui-checked': {
        transform: 'translateX(6px)'
      }
    },
    '& .MuiSwitch-track': {
      my: 'auto',
      height: '9px',
      width: '18px',
      borderRadius: '3.5px'
    },
    '& .MuiSwitch-thumb': {
      my: 'auto',
      width: '12px',
      height: '12px'
    },
    '& .MuiSwitch-root': {
      overflow: 'visible',
      width: '18px',
      height: '12px',
      p: 0
    }
  },
  addNewRowButton: {
    my: '24px',
    p: 0
  },
  conditionsMenu: {
    p: '16px 24px 16px 16px !important'
  },
  queryTitle: {
    fontSize: '12px',
    fontFamily: 'Mulish',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  errorText: {
    color: 'red.light',
    fontSize: '10px',
    fontWeight: 400
  },
  searchHighlighted: {
    overflow: 'hidden',
    position: 'absolute',
    height: '44px',
    background: 'initial',
    transition: 'all .3s ease-in-out',
    borderRadius: '8px',
    width: '73%',
    px: '2px',
    border: '1.5px solid',
    borderColor: 'gray.300',
    backgroundColor: 'transparent',
    ml: '150px',
    '&#search-bar-highlighted': {
      zIndex: 1,
      backgroundColor: 'white',
      width: 760,
      ml: '0 !important',
      borderRadius: '8px',
      borderColor: 'primary.300',
      boxShadow: '0px 0px 6px 3px rgba(10, 128, 109, 0.12)'
    }
  },
  autoComplete: {
    zIndex: 4,
    transition: 'all .3s ease-in-out',
    '&#search-box-highlighted': {
      paddingLeft: '150px',
      zIndex: 2
    },
    '& > div': {
      height: '100%',
      background: 'white',
      border: 'none !important'
    }
  },
  searchContainer: {
    position: 'absolute',
    flexGrow: 1,
    left: {
      xs: '0px',
      sm: '20px',
      md: '20px',
      lg: '32px'
    },
    width: {
      xs: '45%',
      sm: '45%',
      md: '55%',
      lg: '60%'
    },
    maxWidth: {
      xs: '100%',
      sm: '100%',
      md: 532,
      lg: 700
    }
  },
  query: {
    display: 'flex',
    padding: '10px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'primary.0',
    borderRadius: '8px',
    mb: '20px'
  },
  scopeBox: {
    padding: '12px 16px 32px 16px',
    backgroundColor: 'grey.100',
    borderRadius: '8px'
  },
  scopeRadio: {
    ml: 0,
    mt: '14px',
    mr: 0,
    fontFamily: 'Mulish',
    fontSize: '13px',
    fontWeight: 400
  },
  scopeRadioButton: {
    p: 0,
    pr: '6px',
    '& .MuiSvgIcon-root': {
      fontSize: 16
    },
    '&.Mui-checked': {
      color: 'primary.600'
    }
  },
  applyButton: {
    bgcolor: 'primary.650',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    border: 1,
    marginY: 1,
    flex: 1,
    height: '30px',
    borderColor: 'primary.650',
    borderRadius: '16px',
    borderStyle: 'solid',
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: '12px',
    color: 'white.main',
    width: '160px',
    mt: '50px',
    '&:hover': {
      bgcolor: 'primary.700'
    }
  },
  labelSectionBox: {
    maxHeight: '437px',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey.300',
      borderRadius: '5px'
    }
  },
  checkBox: {
    '& .MuiSvgIcon-root': {
      fontSize: 16
    },
    color: '#000000'
  },
  labeldivider: {
    width: '1px',
    height: '393px',
    background: 'grey-300',
    ml: '12px'
  },
  keyDownIconStyle: {
    ml: '10px',
    width: '12px',
    height: '12px',
    color: 'black.main',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: 12
    }
  },
  popOverContainer: {
    p: '16px 24px',
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '437px',
    overflow: 'hidden'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: 'grey.800',
    fontWeight: 700,
    flex: 1,
    flexGrow: 1
  },
  categorytext: { color: 'primary.main', fontWeight: 700 },
  dividerStyle: {
    width: '200px',
    height: '1px',
    background: 'grey-300',
    ml: '12px',
    mb: '8px'
  },
  dividerSubSection: {
    width: '1px',
    height: '70px',
    background: 'grey-300',
    mr: '18px',
    ml: '16px'
  },
  arrowIcon: {
    fontSize: 16,
    color: 'gray.800'
  },
  radioButton: {
    p: '0px',
    '& .MuiSvgIcon-root': {
      fontSize: 16
    }
  },
  categoryBox: {
    width: '160px'
  },
  menuList: {
    pl: '8px',
    '&:hover': {
      background: 'none'
    }
  },
  rightarrow: {
    fontSize: 16,
    rotate: '270deg',
    color: 'gray.800'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'initial',
    justifyContent: 'space-between',
    '&:hover': {
      background: 'none'
    }
  },
  alertBox: {
    width: '584px',
    height: '60px',
    display: 'flex',
    padding: '10px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#FFC4CA'
  },
  alertText: {
    fontSize: 14,
    fontWeight: 600,
    color: 'red.900'
  },
  sectionList: {
    '&:hover': {
      background: 'none'
    },
    cursor: 'initial'
  },
  icon: {
    width: '16px',
    height: '16px',
    cursor: 'pointer'
  },
  textFont: {
    fontSize: '13px'
  },
  dialogBox: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(56,56,56,0.4)'
    },
    '& .MuiPaper-root': {
      elevation: 1,
      borderRadius: 2,
      padding: '24px 32px 32px 32px',
      width: '872px',
      m: 0
    }
  },
  dialogContent: {
    p: 0,
    m: 0,
    height: '490px'
  },
  dividerLine: { width: '126px', height: '1px', color: 'grey.300', mt: '16px', mb: '16px' },
  terminologyInfo: {
    display: 'flex',
    width: '260px',
    padding: '12px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'primary.100',
    backgroundColor: 'primary.50',
    boxShadow: '0px 0px 60px 0px rgba(0, 0, 0, 0.12)'
  },
  infoText: {
    color: 'black.main',
    fontSize: '12px',
    fontWeight: '500',
    display: 'block'
  },
  dividerLabelSection: {
    width: '1px',
    height: 'auto',
    background: 'primary-200'
  },
  infoHeadingText: {
    color: 'grey.500',
    fontSize: '12px',
    fontWeight: '400'
  },
  infoSubHeadingText: {
    color: 'grey.800',
    fontSize: '12px',
    fontWeight: '400'
  },
  iconInfoButton: {
    width: '14px',
    height: '14px',
    color: 'grey.500'
  }
};

export default styles;
