const actions = {
  SET_LOADING: 'SET_LOADING',
  SET_GRAPH_TYPE: 'SET_GRAPH_TYPE',
  SET_CART_ITEMS: 'SET_CART_ITEMS',
  SET_NOTIFICATION_COUNT: 'SET_NOTIFICATION_COUNT',
  SET_CARD_DETAILS: 'SET_CARD_DETAILS',
  SET_USER_STUB: 'SET_USER_STUB',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  SET_PATENT_EXPANSION: 'SET_PATENT_EXPANSION',
  SET_APPLICATION_CONTENT: 'SET_APPLICATION_CONTENT',
  SET_MODULE: 'SET_MODULE',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_REPORT: 'SET_REPORT',
  SET_PDF_MESSAGE: 'SET_PDF_MESSAGE',
  SET_ADVANCE_SEARCH: 'SET_ADVANCE_SEARCH',
  SET_ALERT: 'SET_ALERT',
  SET_DB: 'SET_DB',
  SET_TIMELINE: 'SET_TIMELINE',
  SET_EXTENDED_CARD: 'SET_EXTENDED_CARD',
  SET_BOT: 'SET_BOT',
  SET_ADCOMMCOMMITTEE: 'SET_ADCOMMCOMMITTEE',
  SET_TREE_DATA: 'SET_TREE_DATA',
  SET_SEARCH_DETAILS: 'SET_SEARCH_DETAILS',
  SET_PROCEDURAL_STEPS_PROD_NO: 'SET_PROCEDURAL_STEPS_PROD_NO',
  SET_EMA_PRODUCT_NO: 'SET_EMA_PRODUCT_NO',
  SET_SELECTED_CARD: 'SET_SELECTED_CARD', // selected application (card) for a project.
  SET_PROJECT_DIALOG: 'SET_PROJECT_DIALOG', // open.close projects list dialog box.
  SET_ITEM_TYPE: 'SET_ITEM_TYPE', // item type selected for a project (i.e application, query or report)
  SET_SUBMIT_PROJECT_NAME: 'SET_SUBMIT_PROJECT_NAME', // project name
  SET_ADVANCE_SEARCH_PAYLOAD: 'SET_ADVANCE_SEARCH_PAYLOAD',
  SET_ADVANCE_SEARCH_CNF_QUERY: 'SET_ADVANCE_SEARCH_CNF_QUERY',
  SET_ADVANCE_SEARCH_CONDITIONS: 'SET_ADVANCE_SEARCH_CONDITIONS',
  SET_NORMALIZED_CNF_QUERY: 'SET_NORMALIZED_CNF_QUERY',
  SET_NEW_SEARCH: 'SET_NEW_SEARCH',
  SET_ADCOMM_MINUTES_ADD_DETAILS: 'SET_ADCOMM_MINUTES_ADD_DETAILS',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
  SET_UNREAD_MESSAGES: 'SET_UNREAD_MESSAGES',
  SET_SHARE_LINK: 'SET_SHARE_LINK',
  SET_SERVICE_RESTRICTION_DETAILS: 'SET_SERVICE_RESTRICTION_DETAILS',
  SET_APPLICATION_NAME: 'SET_APPLICATION_NAME',
  SET_APPLICATION_NUMBER: 'SET_APPLICATION_NUMBER',
  SET_APPLICATION_SOURCE: 'SET_APPLICATION_SOURCE',
  SET_APPLICATION_LIST: 'SET_APPLICATION_LIST',
  RESET_ADD_TO_REPORT: 'RESET_ADD_TO_REPORT',
  SET_ARIA_DOCUMENT: 'SET_ARIA_DOCUMENT',
  SET_CHATRIA_TRIGGERED_FROM: 'SET_CHATRIA_TRIGGERED_FROM',
  TRIGGER_CHATRIA_CLOSE_SESSION: 'TRIGGER_CHATRIA_CLOSE_SESSION',
  SET_CHATRIA_OPEN: 'SET_CHATRIA_OPEN',
  ADD_TEMPLATE_TO_REPORT: 'ADD_TEMPLATE_TO_REPORT',
  UPDATE_REPORT_SECTION_POSITION: 'UPDATE_REPORT_SECTION_POSITION',
  UPDATE_REPORT_SECTION_TEXT: 'UPDATE_REPORT_SECTION_TEXT',
  UPDATE_REPORT_HEADER_LOGO: 'UPDATE_REPORT_HEADER_LOGO',
  UPDATE_GRAPH_STYLE: 'UPDATE_GRAPH_STYLE',
  SET_USER_PROFILE_DOCUMENT_OPEN: 'SET_USER_PROFILE_DOCUMENT_OPEN',
  SET_USER_PROFILE_DOCUMENT_LINK: 'SET_USER_PROFILE_DOCUMENT_LINK',
  SET_USER_PROFILE_DOCUMENT_STATE: 'SET_USER_PROFILE_DOCUMENT_STATE',
  SET_USER_PROFILE_SELECTED_DOCUMENT: 'SET_USER_PROFILE_SELECTED_DOCUMENT',
  SET_SCROLL_TO_ARIA_PAGE: 'SET_SCROLL_TO_ARIA_PAGE',
  REMOVE_REPORT_SECTION: 'REMOVE_REPORT_SECTION',
  DUPLICATE_REPORT_SECTION: 'DUPLICATE_REPORT_SECTION',
  REPLACE_REPORT_LAYOUT: 'REPLACE_REPORT_LAYOUT',
  UPDATE_REPORT_SECTIONS_POSITION: 'UPDATE_REPORT_SECTIONS_POSITION',
  UPDATE_COVERPAGE_DESCRIPTION: 'UPDATE_COVERPAGE_DESCRIPTION',
  UPDATE_COVERPAGE_TITLE: 'UPDATE_COVERPAGE_TITLE',
  UPDATE_COVERPAGE_TEMPLATE: 'UPDATE_COVERPAGE_TEMPLATE',
  UPDATE_REPORT_OWNER: 'UPDATE_REPORT_OWNER',
  SET_REPORT_LAYOUT: 'SET_REPORT_LAYOUT',
  SET_REPORT_ID: 'SET_REPORT_ID',
  CLEAR_REPORT: 'CLEAR_REPORT',
  REPORT_DOWNLOADING: 'REPORT_DOWNLOADING',
  REPORT_SAVING: 'REPORT_SAVING',
  REMOVE_CHAT_RIA_REPORT: 'REMOVE_CHAT_RIA_REPORT',
  SET_SHOW_NEW_FILTERS: 'SET_SHOW_NEW_FILTERS',
  SET_LOGIN_SCREEN: 'SET_LOGIN_SCREEN',
  SET_NEW_USER: 'SET_NEW_USER',
  SET_SOURCE_MAINTENANCE: 'SET_SOURCE_MAINTENANCE',
  SET_EXPORT_TYPE: 'SET_EXPORT_TYPE',
  SET_MINIMIZED_CHAT_RIA: 'SET_MINIMIZED_CHAT_RIA',
  UPDATE_SNAPSHOT_REPORT_LAYOUT: 'UPDATE_SNAPSHOT_REPORT_LAYOUT',
  SET_LABEL_SECTION_LIST_ADVANCE_SEARCH: 'SET_LABEL_SECTION_LIST_ADVANCE_SEARCH',
  SET_WHATS_NEW_UPDATES: 'SET_WHATS_NEW_UPDATES',
  SET_IS_WHATS_NEW_LOADING: 'SET_IS_WHATS_NEW_LOADING'
};

export default actions;
