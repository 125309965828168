/* eslint-disable import/prefer-default-export */
import { FILTER_TYPE } from '../../../components/NewFilters/constants';

export const statsId: Record<number, Record<string, string>> = {
  0: { value: 'total_meetings', title: 'Total Meetings' },
  1: { value: 'total_documents', title: 'Total Documents' },
  2: { value: 'total_pages', title: 'Total Pages' }
};

export const resourceKeyMapping: Record<string, string> = {
  prom_meetings: 'Prom Meetings',
  minutes: 'Minutes',
  agendas: 'Agendas',
  prom_agendas: 'Prom Agendas',
  prom_minutes: 'Prom Minutes'
};

export const chmpFiltersMapping: Array<Record<string, string | Array<string>>> = [
  {
    id: 'active_ingredient',
    label: 'Active Ingredient',
    filterType: 'select_options',
    options: [],
    apiRequestKey: 'active_ingredient',
    apiResponseKey: 'active_ingredient'
  },
  {
    id: 'brand_name',
    label: 'Brand Name',
    filterType: 'select_options',
    options: [],
    apiRequestKey: 'brand_name',
    apiResponseKey: 'brand_name'
  },
  {
    id: 'product_number',
    label: 'Product Number',
    filterType: 'select_options',
    options: [],
    apiRequestKey: 'product_number',
    apiResponseKey: 'product_number'
  },
  {
    id: 'sponsor_name',
    label: 'Sponsor Name',
    filterType: 'select_options',
    options: [],
    apiRequestKey: 'sponsor_name',
    apiResponseKey: 'sponsor_name'
  }
];

export const monthOrder: { [key: string]: number } = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11
};

export const barChartStyles = {
  colors: ['#6868FA'],
  padding: 0.5,
  borderRadius: 8,
  axisText: '#7b808c'
};

export const pieChartStyles = {
  colors: ['#079992', '#38ada9', '#78e08f', '#05c46b', '#0be881'],
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: false,
  pieLegend: true,
  innerRadius: 0.5,
  enableLabels: 'both',
  legends: [
    {
      anchor: 'bottom',
      direction: 'column',
      justify: false,
      translateX: 200,
      translateY: 20,
      itemsSpacing: 0,
      itemWidth: 42,
      itemHeight: 18,
      itemTextColor: '#999',
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: 9,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: {
            itemTextColor: '#000'
          }
        }
      ]
    }
  ]
};

export const CHMP_REPORT_QUERIES = [
  {
    id: 'meetings_by_year',
    title: {
      title: 'Total Number of Unique Products by Years',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['year'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: barChartStyles
  },
  {
    id: 'meeting_insights',
    title: {
      title: 'Meeting Insights',
      show: true
    },
    widget: 'pie-widget',
    graph: 'PIE',
    query: {
      'x-axis': ['highlights'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  }
];

export const CHMP_FILTERS = [
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Brand Name',
    value: 'brand_name',
    elasticSearchType: 'brand_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Product Number',
    value: 'product_number',
    elasticSearchType: 'product_number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  }
];
