import { Collapse, Divider, FormControlLabel, Stack, Tooltip } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import styles from '../styles/SearchBarAutoComplete.styles';
import CustomSwitch from './CustomSwitch';
import ResultsStore from '../../../store/SearchResults';

const RetainFilters = ({
  show,
  disabled,
  onChange,
  value
}: {
  show: boolean;
  disabled: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (val: any) => void;
  value: boolean;
}) => {
  const [filtersExist, setFiltersExist] = React.useState(false);
  const { resultsState } = useContext(ResultsStore);
  useEffect(() => {
    setFiltersExist(Object.keys(resultsState?.filters ?? {}).length > 0);
  }, [resultsState?.filters]);
  return (
    <Collapse in={show} orientation='horizontal' collapsedSize={0} unmountOnExit>
      <Stack direction='row' justifyContent='center'>
        <Divider orientation='vertical' flexItem sx={styles.divider} />
        {!filtersExist ? (
          <Tooltip title='No filters to retain' arrow placement='bottom'>
            <FormControlLabel
              disabled
              onChange={onChange}
              checked={false}
              defaultChecked={false}
              control={<CustomSwitch />}
              label='Retain Filter(s)'
              labelPlacement='start'
              sx={styles.switchRoot}
            />
          </Tooltip>
        ) : (
          <Tooltip
            open={disabled}
            arrow
            title={
              disabled
                ? 'Filters cannot be retained as sources changed'
                : 'Retain filters for next search'
            }
            placement='bottom'>
            <FormControlLabel
              disabled={disabled}
              onChange={onChange}
              checked={!disabled && value}
              defaultChecked={!disabled && value}
              control={<CustomSwitch />}
              label='Retain Filter(s)'
              labelPlacement='start'
              id={!disabled && value ? 'retain-filters' : ''}
              sx={styles.switchRoot}
            />
          </Tooltip>
        )}
        <Divider orientation='vertical' flexItem sx={styles.divider} />
      </Stack>
    </Collapse>
  );
};
export default RetainFilters;
