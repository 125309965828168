import { isEmpty, intersection } from 'lodash';
import {
  ADVANCED_SEARCH_REGION_LIST,
  ADVANCED_SEARCH_SCOPE_LIST,
  SOURCE_MENU_ITEMS
} from './const';
import { AdvancedSearchScopeRegion, Module, SourceDropdown } from './types';
import { WHATS_NEW_TABS } from '../Help/utils/constants';
import { WhatsNewEntry } from '../../components/WhatsNew/types';

// eslint-disable-next-line no-unused-vars
export const getFilteredSourceDropdown = (filterFunction: (module: any) => boolean) => {
  return SOURCE_MENU_ITEMS.map(sourceDropdown => {
    const filteredModules = sourceDropdown.module.filter(module => filterFunction(module));
    return { ...sourceDropdown, module: filteredModules };
  }).filter(sourceDropdown => sourceDropdown.module.length > 0);
};

export const getDefaultSourceDropdown = (dropdown: SourceDropdown[]) => {
  if (isEmpty(dropdown)) {
    return [];
  }
  const finalDropDown: SourceDropdown[] = [
    {
      ...dropdown[0],
      module: [dropdown[0].module[0]]
    }
  ];
  return finalDropDown;
};

// For homepage
export const getDefaultHomePageSourceDropDown = () => {
  const sourceDropdown = SOURCE_MENU_ITEMS;
  return getDefaultSourceDropdown(sourceDropdown);
};

// For Applications
export const getApplicationsSourceDropdown = (isLabelComparisionSelection = false) => {
  return !isLabelComparisionSelection
    ? getFilteredSourceDropdown((module: Module) => module?.isApplicationsView ?? false)
    : getFilteredSourceDropdown(
        (module: Module) => (module?.isApplicationsView ?? false) && (module?.isCompare ?? false)
      );
};

export const getDefaultApplicationsSourceDropDown = () => {
  const applicationsSourceDropdown = getApplicationsSourceDropdown();
  return getDefaultSourceDropdown(applicationsSourceDropdown);
};

export const getCTSourceDropdown = () =>
  SOURCE_MENU_ITEMS.filter((item: SourceDropdown) => item.value === 'ct');

export const getDefaultCTSourceDropDown = () => {
  const ctSourceDropdown = getCTSourceDropdown();
  return getDefaultSourceDropdown(ctSourceDropdown);
};

// For CDP
export const getCDPSourceDropdown = () => {
  return getFilteredSourceDropdown((module: Module) => module?.isCDP ?? false);
};

export const getDefaultCDPSourceDropDown = () => {
  const cdpSourceDropdown = getCDPSourceDropdown();
  return getDefaultSourceDropdown(cdpSourceDropdown);
};

// For Reg360
export const getReg360SourceDropdown = () => {
  return getFilteredSourceDropdown((module: Module) => module?.isReg360 ?? false);
};

export const getDefaultReg360SourceDropDown = () => {
  const reg360SourceDropDown = getReg360SourceDropdown();
  return getDefaultSourceDropdown(reg360SourceDropDown);
};

// For Compare
export const getCompareSourceDropdown = () => {
  return getFilteredSourceDropdown((module: Module) => module?.isCompare ?? false);
};

export const getDefaultCompareSourceDropdown = () => {
  const compareSourceDropdown = getCompareSourceDropdown();
  return getDefaultSourceDropdown(compareSourceDropdown);
};

// used to create the payload from the source module dropdown
// Eg sourceMenuItems: Check SOURCE_MENU_ITEMS in const.ts
// Eg response: { us:['sba','wr'],jp:['pmda']}
export const createSourceModulePayloadMapping = (sourceMenuItems: SourceDropdown[]) => {
  if (!sourceMenuItems) {
    return {};
  }
  const moduleMapping: any = {};
  sourceMenuItems.forEach(sourceDropdown => {
    sourceDropdown.module.forEach(module => {
      const { sourceValue, value } = module;

      if (!moduleMapping[sourceValue]) {
        moduleMapping[sourceValue] = [];
      }

      moduleMapping[sourceValue].push(value);
    });
  });
  return moduleMapping;
};

// used to create the dropdown with SourceDropdown type from the source payload
// Eg sourcePayload: { us:['sba','wr'],jp:['pmda']}
// Eg response: Check SOURCE_MENU_ITEMS in const.ts
export const createSourceModuleDropdownMapping = (sourcePayload: {}) => {
  if (isEmpty(sourcePayload)) {
    return getDefaultHomePageSourceDropDown();
  }
  const selectedSources: SourceDropdown[] = [];
  Object.entries(sourcePayload).forEach(([source, valueList]) => {
    (valueList as Array<any>).forEach((payloadModuleVal: string) => {
      let moduleFoundVal = {} as Module;
      const sourceModuleDropdownFound = SOURCE_MENU_ITEMS.find(sourceDropdownVal => {
        const foundModule = sourceDropdownVal.module.find(
          module => module.sourceValue === source && module.value === payloadModuleVal
        );
        if (foundModule) {
          moduleFoundVal = foundModule;
        }
        return foundModule;
      });
      if (isEmpty(sourceModuleDropdownFound) || isEmpty(moduleFoundVal)) return;
      const moduleAlreadyPresent = selectedSources.find(
        sourceObj => sourceObj.value === sourceModuleDropdownFound.value
      );
      if (moduleAlreadyPresent) {
        moduleAlreadyPresent.module.push(moduleFoundVal);
      } else {
        selectedSources.push({
          ...sourceModuleDropdownFound,
          module: [moduleFoundVal]
        });
      }
    });
  });
  return selectedSources;
};

/**
 * This function is used to extract the modules that are present in the default source dropdown and remove the extra modules
 * @param defaultSourceDropdown - the default source dropdown that is used to create the source dropdown
 * @param sourceDropdown - the source dropdown with extra modules that needs to be removed
 * @returns the source dropdown with the modules that are present in the default source dropdown
 */
export const extractSourceDropdown = (
  defaultSourceDropdown: SourceDropdown[],
  sourceDropdown: SourceDropdown[]
) => {
  if (isEmpty(sourceDropdown) || isEmpty(defaultSourceDropdown)) {
    return [];
  }
  const newSelectedSources: SourceDropdown[] = [];
  defaultSourceDropdown.forEach((source: SourceDropdown) => {
    const foundSource = sourceDropdown.find(
      (selectedSource: SourceDropdown) => selectedSource.value === source.value
    );
    if (foundSource) {
      const newModules = source.module.filter(module => {
        const foundModule = foundSource.module.find(
          selectedModule => selectedModule.value === module.value
        );
        return foundModule;
      });
      if (newModules.length > 0) {
        newSelectedSources.push({ ...source, module: newModules });
      }
    }
  });
  return newSelectedSources;
};

/**
 * This function is used to find the relevant advanced search scope and region based on the source menu items
 * @param sourceMenuItems - the source menu items that are selected
 * @returns - a list of scope and region that are relevant to the selected source menu items
 */
export const findRelevantAdvancedSearchScopeRegion = (sourceMenuItems: SourceDropdown[]) => {
  const relevantScopeRegions: {
    scope: AdvancedSearchScopeRegion;
    region: AdvancedSearchScopeRegion;
  }[] = [];

  sourceMenuItems.forEach(source => {
    source.module.forEach(moduleItem => {
      // Find matching region
      const matchingRegion = ADVANCED_SEARCH_REGION_LIST.find(region =>
        Object.values(region?.module ?? {}).find(moduleValue => moduleValue === moduleItem.value)
      );

      // Find matching scope
      const matchingScope = ADVANCED_SEARCH_SCOPE_LIST.find(
        scope => scope.value === moduleItem.advanceSearchScope
      );
      if (matchingScope && matchingRegion) {
        relevantScopeRegions.push({ scope: matchingScope, region: matchingRegion });
      }
    });
  });

  return relevantScopeRegions;
};

export const getWhatsNewStats = (last30DaysUpdates: WhatsNewEntry[]) => {
  const stats = {
    majorUpdates: 0,
    enhancementUpdates: 0
  };

  last30DaysUpdates.forEach(update => {
    if (update.type === WHATS_NEW_TABS.majorUpdates) {
      stats.majorUpdates += update.updates.length;
    }

    if (update.type === WHATS_NEW_TABS.enhancementUpdates) {
      stats.enhancementUpdates += update.updates.length;
    }
  });

  return stats;
};

export const getWhatsNewUnseenCount = (last30DaysUpdates: WhatsNewEntry[]) => {
  const localStorageSeenUpdateIdsString = localStorage.getItem('WN_last30DaySeenUpdateIds') || '[]';
  const localStorageSeenUpdateIds = JSON.parse(localStorageSeenUpdateIdsString);

  const newUpdateIds: Array<number> = [];

  const latestWhatsNewIds = {
    majorUpdates: [] as Array<number>,
    enhancementUpdates: [] as Array<number>
  };

  last30DaysUpdates.forEach(update => {
    update.updates.forEach(currentUpdate => {
      newUpdateIds.push(currentUpdate.id);

      if (update.type === WHATS_NEW_TABS.majorUpdates) {
        latestWhatsNewIds.majorUpdates.push(currentUpdate.id);
      }

      if (update.type === WHATS_NEW_TABS.enhancementUpdates) {
        latestWhatsNewIds.enhancementUpdates.push(currentUpdate.id);
      }
    });
  });

  localStorage.setItem('WN_last30DaysUpdateIds', JSON.stringify(newUpdateIds));
  localStorage.setItem('WN_latestIds', JSON.stringify(latestWhatsNewIds));

  const newSeenUpdateIds = intersection(localStorageSeenUpdateIds, newUpdateIds);

  localStorage.setItem('WN_last30DaySeenUpdateIds', JSON.stringify(newSeenUpdateIds));

  return newUpdateIds.length - newSeenUpdateIds.length;
};
